import { defineStore } from 'pinia';
import * as Sentry from "@sentry/nuxt";
import { useApi } from '~/composables/useApi'; 
import type { Notification } from '~/types';
import { useAuthStore } from '~/stores/auth'; 
const { isNotificationsSlideoverOpen } = useDashboard();
let notificationsToastId: string | null = null;

export const useNotificationStore = defineStore('notificationStore', {
  state: () => ({
    notifications: [] as Notification[],
    unreadCount: 0,
    pollingInterval: null as ReturnType<typeof setInterval> | null, // Polling interval to fetch notifications periodically
    loading: false,
  }),
  getters: {
    // Returns true if there are unread notifications
    hasUnreadNotifications(state): boolean {
      return state.unreadCount > 0;
    },
  },
  actions: {
    // Fetches notifications and checks if there are new ones
    async fetchNotifications() {
      const authStore = useAuthStore();
      this.loading = true;
      try {
        const { data } = await useApi('/v1/notifications', { default: () => [] });
        const previousNotifications = [...this.notifications];
    
        this.notifications = data.value;
    
        // Check for new notifications by comparing their IDs
        const newNotifications = this.notifications.filter(notification =>
          !previousNotifications.some(prev => prev.id === notification.id)
        );
    
        // Filter out the new notifications that are unread
        const unreadNewNotifications = newNotifications.filter(notification => notification.isRead === false);
    
        // Only show the toast if there are unread new notifications
        if (unreadNewNotifications.length > 0) {
          let title = '';
          // If it's the first time the component is loaded
          if (previousNotifications.length === 0) {
            title = `You have ${unreadNewNotifications.length > 1 ? unreadNewNotifications.length + ' unread notifications' : 'a new unread notification'}`;
          } else {
            // If there was a previous load, show a generic message when a new notification is received. 
            title = 'You have a new notification';
          }
    
          const toast = useToast();
          notificationsToastId = toast.add({
            title,
            description: 'Click here to view the notifications.',
            actions: [{
              label: 'Open Notifications',
              color: 'primary',
              click: () => {
                isNotificationsSlideoverOpen.value = true;
              }
            }],
            timeout: 5000,
            icon: 'i-heroicons-bell',
            ...toastConstants.INFO,
          }).id;
          const checkToastInterval = setInterval(() => {
            if (!authStore.isAuthenticated && notificationsToastId !== null) {
                this.closeNotificationsToast();
                clearInterval(checkToastInterval);
            }
        }, 1000);
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        this.loading = false;
      }
    },
    // Fetch the count of unread notifications
    async fetchUnreadCount() {
      try {
        const { data } = await useApi('/v1/notifications/unread-count', { default: () => 0 });
        this.unreadCount = data.value;
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    // Mark Notification As Read
    async markNotificationAsRead(id: number) {
      try {
        await useApi(`/v1/notifications/${id}/read`, {
          method: 'PUT',
        });
        // Update the notification's state locally
        const notification = this.notifications.find(n => n.id === id);
        if (notification) {
          notification.isRead = true;
          this.unreadCount--; // Decrement the unread count
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async markNotificationAsUnread(id: number) {
      try {
        await useApi(`/v1/notifications/${id}/unread`, {
          method: 'PUT',
        });
        // Update the notification's state locally
        const notification = this.notifications.find(n => n.id === id);
        if (notification) {
          notification.isRead = false; // Mark it as unread
          this.unreadCount++; // Increment the unread count
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    startPolling() {
      if (!this.pollingInterval) {
        // Fetch notifications immediately when the polling starts
        this.fetchNotifications();
        this.fetchUnreadCount();

        // Then start the periodic polling
        this.pollingInterval = setInterval(async () => {
          await this.fetchNotifications();
          await this.fetchUnreadCount();
        }, 30000); // Poll every 30 seconds
      }
    },
    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }
    },
    async closeNotificationsToast() {
      const toast = useToast();
      if (notificationsToastId !== null) {
          toast.remove(notificationsToastId);
          notificationsToastId = null;
      }
    }
  },
});
