<script setup lang="ts">
import { ref } from 'vue';
import * as Sentry from "@sentry/nuxt";
import type { PaginatedCasesResponse } from '~/models/CaseModels';
const toast = useToast();
const router = useRouter();
const quickSearchInput = ref<{ input: HTMLInputElement }>();
const currFilter = ref({
    id: 0,
    Name: 'caseId',
    Value: ''
});

const query = computed(() => ({
    pageNumber: 1,
    pageSize: 1,
}));

//TODO: define shortcut key combination here, for test purposes '/''
defineShortcuts({
    '/': () => {
        quickSearchInput.value?.input?.focus();
    },
});

const cleanSearch = () => {
    currFilter.value.Value = '';
}

async function search() {
    try{
        const { data: response } = await useApi<PaginatedCasesResponse>(`/v1/cases/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify([currFilter.value]), 
            query: query.value
        });
        if(response.value.totalCases === 1){
            toast.add({title: 'Case found, loading data...', ...toastConstants.INFO })
            await router.push(`/case/${response.value.cases[0].caseId}/summary`);
        }else {
            toast.add({title: 'Case not found!', ...toastConstants.ERROR })
        }
    } catch (error) {
        Sentry.captureException(error);
        toast.add({title: 'Failed to search for cases.', ...toastConstants.ERROR })
    }
    cleanSearch();
}

</script>

<template>
    <div class="mb-4">
        <UTooltip text="Quick Case Search" :shortcuts="['/']">
            <UInput 
                ref="quickSearchInput" 
                v-model="currFilter.Value" 
                placeholder="Go to Case" 
                icon="i-heroicons-magnifying-glass" 
                @keydown.enter="search"
            />
        </UTooltip>
    </div>
</template>

  